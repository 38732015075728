import { Footer } from './components/Footer';
import { Navbar } from './components/Navbar';
import SectionTop from './components/SectionTop';
import { ServicesSection } from './components/ServicesSection';

function App() {
  return (
    <>
      <Navbar />
      <SectionTop />
      <ServicesSection />
      <Footer />
    </>
  );
}

export default App;
