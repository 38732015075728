import BannerTop from '../../assets/img/web-designer-setting-up-web-layout.gif';
const SectionTop = () => {
  return (
    <>
      <section className="container px-4 py-10 mx-auto lg:h-128 lg:space-x-8 lg:flex lg:items-center">
        <div className="w-full text-center lg:text-left lg:w-1/2 lg:-mt-8">
          <h1 className="text-3xl leading-snug text-gray-800 md:text-4xl">
            A<span className="font-semibold">free repository</span>
            for community
            <br /> components using
            <span className="font-semibold underline decoration-primary">
              Tailwind CSS
            </span>
          </h1>
          <p className="mt-4 text-lg text-gray-500">
            Open source Tailwind UI components and templates to
            <br /> bootstrap your new apps, projects or landing sites!
          </p>
          <button>aqui</button>
        </div>
        <div className="w-full mt-4 lg:mt-0 lg:w-1/2">
          <img
            src={BannerTop}
            alt="web designer"
            className="w-full h-full max-w-md mx-auto"
          />
        </div>
      </section>
    </>
  );
};

export default SectionTop;
